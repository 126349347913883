import { toast } from 'react-toastify'
import { useRef, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'
import download from 'downloadjs'

// Components
import CustomIcon from '../CustomIcon'
import DropDown from '../DropDown'
import Spinner from '../Spinner'
import AlertDialogDemo from '../AlertDialogDemo'

// Services
import { deleteFileFromVectorStore } from '../../services/readDocsServices'

import './index.scss'
import CustomTooltip from '../CustomTooltip'

function FileList({ files, currentFile, setCurrentFile, isFetching }) {
  const [modalOpen, setModalOpen] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [fileToDelete, setFileToDelete] = useState(null)

  const { id } = useParams()

  const currentFileRef = useRef(null)

  const queryClient = useQueryClient()

  function handleChangeCurrentFile(type) {
    if (type === 'next') {
      if (currentFile < files.length - 1) {
        setCurrentFile(currentFile + 1)
      }
    } else {
      if (currentFile >= 1) {
        setCurrentFile(currentFile - 1)
      }
    }
  }

  async function handleConfirmDeleteFile() {
    try {
      if (files.length === 1) {
        toast.error('Não é possível deletar o único arquivo do chat')
        return
      }
      setIsLoading(true)
      setCurrentFile(0)
      await deleteFileFromVectorStore({ chatId: id, fileId: fileToDelete })
      queryClient.invalidateQueries('document-download')
      toast.success('Arquivo deletado com sucesso')
    } catch (error) {
      console.log(error)
      toast.error('Erro ao deletar arquivo')
    } finally {
      setIsLoading(false)
      setModalOpen('')
    }
  }

  function handleDeleteFile(fileId) {
    setFileToDelete(fileId)
    setModalOpen('delete-file')
  }

  function handleDownloadFile() {
    download(window.atob(files[currentFile]?.fileBase64), files[currentFile]?.filename)
  }

  useEffect(() => {
    if (currentFileRef.current) {
      currentFileRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'end',
      })
    }
  }, [currentFile])

  return (
    <nav className='file-list'>
      <div className='file-list__header'>
        <div className='page__display'>
          <input type='text' value={currentFile + 1} className='page__display--current' disabled />
          <p>/</p>
          <p>{files.length}</p>
        </div>
        {isFetching && <Spinner size={1} />}
        <div>
          <button
            className={`pdf__controller--icon ${currentFile === 0 && 'icon--off'}`}
            onClick={() => {
              handleChangeCurrentFile('prev')
            }}>
            <CustomIcon icon='ArrowLeft' size={16} />
          </button>
          <button
            className={`pdf__controller--icon ${currentFile === files.length - 1 && 'icon--off'}`}
            onClick={() => {
              handleChangeCurrentFile('next')
            }}>
            <CustomIcon icon='ArrowRight' size={16} />
          </button>
        </div>
      </div>
      <div className='file-list__content'>
        {files.map((file, index) => (
          <div
            key={`file-card-${index}`}
            className={`file-card${index === currentFile ? '--highlight' : ''} ${
              !file?.openAiId ? 'file-card--ocr' : ''
            }`}
            ref={index === currentFile ? currentFileRef : null}>
            {/* get the first 10 characters of the filename */}
            <button onClick={() => setCurrentFile(index)}>
              {file?.filename?.length > 15
                ? `${file?.filename?.substring(0, 15)}...`
                : file?.filename}
            </button>
            {!file?.openAiId ? (
              <CustomTooltip
                name='Processamento em andamento'
                description='O arquivo ainda não está disponível no escopo da IA. Por favor, aguarde alguns momentos enquanto o serviço é realizado.'>
                <CustomIcon icon='AlertCircle' size={16} strokeWidth={3} color='#006192' />
              </CustomTooltip>
            ) : (
              <DropDown
                noArrow
                options={[
                  {
                    label: 'Excluir',
                    action: () => handleDeleteFile(file.openAiId),
                    icon: 'Trash',
                  },
                  {
                    label: 'Baixar',
                    action: handleDownloadFile,
                    icon: 'DownloadCloud',
                  },
                ]}>
                <CustomIcon icon={'MoreVertical'} size={'12px'} color='#64feda' />
              </DropDown>
            )}
          </div>
        ))}
      </div>
      <AlertDialogDemo
        title={'Excluir documento?'}
        description={
          'Clique em confirmar para deletar o documento. Esta ação não pode ser desfeita.'
        }
        cancelButton={'Cancelar'}
        confirmButton={'Confirmar'}
        modalOpen={modalOpen === 'delete-file'}
        setModalOpen={setModalOpen}
        confirmAction={handleConfirmDeleteFile}
        loading={isLoading}
      />
    </nav>
  )
}

export default FileList
