import React from 'react'

import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { useState, useEffect } from 'react'
import axios from 'axios'
import { toast } from 'react-toastify'
import { Input, Button, Select } from '../../components/DesignSystem'
import DialogDemo from '../../components/DialogDemo'
import AlertDialogDemo from '../../components/AlertDialogDemo'
import { Check } from 'lucide-react'
import states from '../../constants/states.json'
import { useNavigate } from 'react-router-dom'
import {
  redefinePassword,
  redefineProfileData,
  verifyCurrentPassword,
} from '../../services/authServices'
import {
  creditCard,
  cvvValidator,
  expiredAt,
  formatDocument,
  onlyNumber,
  validatePassword,
  phone as phoneMask,
} from '../../constants/validate'
import blocked_email_providers from '../../constants/blocked_email_providers.txt'

import './index.scss'
import { createSignature, cancel, updateSignature } from '../../services/subscriptionServices'

const GeneralConfigurations = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  // Definindo as informações do usuário
  const { user } = useSelector(state => state.app)

  // Definindo os dados do usuário
  const [name, setName] = useState('')
  const [surname, setSurname] = useState('')
  const [email, setEmail] = useState('')

  // Definindo modalOpen
  const [modalOpen, setModalOpen] = useState('')

  // Carregamento
  const [isLoading, setIsLoading] = useState(false)

  // Nova senha
  const [passwords, setPasswords] = useState({
    current: '',
    newPassword: '',
    confirmNewPassword: '',
  })

  // Lista de email "inválidos"
  const [emailBlocklist, setEmailBlocklist] = useState([])

  // Credit card infos
  const [cardNumber, setCardNumber] = useState('')
  const [cardName, setCardName] = useState('')
  const [cardExpirationDate, setCardExpirationDate] = useState('')
  const [cardCvv, setCardCvv] = useState('')
  const [document, setDocument] = useState('')
  const [street, setStreet] = useState('')
  const [streetNumber, setStreetNumber] = useState('')
  const [neighborhood, setNeighborhood] = useState('')
  const [zipcode, setZipcode] = useState('')
  const [phone, setPhone] = useState('')
  const [selectedState, setSelectedState] = useState('')
  const [selectedCity, setSelectedCity] = useState('')
  const [cityOptions, setCityOptions] = useState([])

  // Atualizando as informações do usuário
  useEffect(() => {
    setName(user.name)
    setSurname(user.surname)
  }, [user.name, user.surname])

  useEffect(() => {
    if (selectedState) {
      let value = states.find(state => state.value === selectedState)
      setCityOptions(value.cities)
    }
  }, [selectedState])

  // Função que válida se o novo nome digitado é válido
  async function verifyNameChanges(e) {
    e.preventDefault()

    function verifyChanges() {
      const isNull = name === '' || surname === ''
      const isDifferet = name === user.name && surname === user.surname

      if (isDifferet) {
        toast.warning('Os dados não foram alterados')
        return false
      }
      if (isNull) {
        toast.warning('Os dados estão inválidos')
        return false
      }
      return true
    }

    if (!verifyChanges()) return
    setModalOpen('profile-changes')
  }

  // Função que válida se o novo email digitado é válido
  async function verifyEmailChanges() {
    const password = passwords.current

    const getEmailBlockList = async () => {
      let emailBlocklist = await fetch(blocked_email_providers)
      emailBlocklist = await emailBlocklist.text()
      emailBlocklist = emailBlocklist.replace(/(\r)/g, '').split('\n')
      setEmailBlocklist(emailBlocklist)
    }
    getEmailBlockList()

    function isEmail(emailAdress) {
      let regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/

      if (emailAdress.match(regex)) return !emailBlocklist.includes(emailAdress.split('@')[1])
      else return false
    }

    const emailValidate = isEmail(email)

    if (!emailValidate || email === '') {
      toast.error('Insira um email válido')
      return
    }

    if (email === user.email) {
      toast.error('Insira um email diferente')
      return
    }

    if (password === '') {
      toast.error('Insira sua senha')
      return
    }

    try {
      await verifyCurrentPassword(password)
    } catch (error) {
      toast.error(error.response.data.errors[0].message)
      return
    }

    setIsLoading(true)
    submitUserChanges()
  }

  // Função que salva o novo nome do usuário
  async function submitUserChanges() {
    setIsLoading(true)
    const newData = {
      name,
      surname,
      email,
    }
    try {
      const { data } = await redefineProfileData(newData)
      dispatch({ type: 'CHANGE_USER', payload: data })
      localStorage.setItem('user', JSON.stringify(data))
      toast.success('Alterações salvas com sucesso')
      setModalOpen('')
    } catch (error) {
      console.log(error)
      toast.error(error.response.data.errors[0].message)
    } finally {
      setIsLoading(false)
    }
  }

  // Manipulando o state das senhas (atual, nova, confirmação da nova)
  function changePasswords(key, value) {
    let cPasswords = { ...passwords }
    cPasswords[key] = value
    setPasswords(cPasswords)
  }

  // Função que faz a verificação da nova senha
  async function verifyPassword() {
    const password = passwords.current

    if (password === '') {
      toast.error('Senha inválida')
      return
    }

    try {
      await verifyCurrentPassword(password)
    } catch (error) {
      toast.error(error.response.data.errors[0].message)
      return
    }

    if (passwords.newPassword === '') {
      toast.error('Nova senha obrigatória')
      return
    }
    if (passwords.newPassword !== passwords.confirmNewPassword) {
      toast.error('Senhas não coincidem')
      return
    }
    if (passwords.newPassword === passwords.current) {
      toast.error('Sua nova senha deve ser diferente da atual')
      return
    }
    if (!validatePassword(passwords.newPassword)) {
      toast.error(
        'Sua senha deve ter no mínimo 8 caracteres contendo letras maiúsculas e minúsculas, números e caracteres especiais'
      )
      return
    }

    setIsLoading(true)
    submitPasswordChange()
  }

  // Função que atualiza a senha antiga por uma nova
  async function submitPasswordChange() {
    try {
      const { data } = await redefinePassword(passwords.newPassword)
      toast.success(data.message)
      setPasswords({
        current: '',
        newPassword: '',
        confirmNewPassword: '',
      })
      setModalOpen('')
    } catch (error) {
      console.log(error)
      toast.error('Erro interno do servidor')
    } finally {
      setIsLoading(false)
    }
  }

  async function cancelSignature() {
    try {
      setIsLoading(true)
      await cancel()
      let newUser = user
      newUser.status = 'canceled'
      newUser.planId = null
      dispatch({ type: 'CHANGE_USER', payload: newUser })
      toast.success('Assinatura cancelada com sucesso')
      setIsLoading(false)
    } catch (error) {
      console.log(error)
      toast.error(error.response.data.errors[0].message)
    } finally {
      setModalOpen('')
    }
  }

  function verifySignatureData() {
    if (user.status === 'paid' || user.status === 'unpaid') {
      setIsLoading(true)
      if (cardNumber === '') {
        toast.error('Número do cartão inválido')
        return
      }
      if (cardName === '') {
        toast.error('Nome do cartão inválido')
        return
      }
      if (cardExpirationDate === '') {
        toast.error('Data do cartão inválida')
        return
      }
      if (cardCvv === '') {
        toast.error('CVV do cartão inválido')
        return
      }
      if (document === '') {
        toast.error('Documentação inválida')
        return
      }
      update()
    } else {
      navigate('/pagamento')
    }
  }

  async function update() {
    let body = {
      cardNumber: onlyNumber(cardNumber),
      expirationDate: cardExpirationDate,
      cvv: cardCvv,
      holderName: cardName,
      document,
    }

    try {
      await updateSignature(body)
      setCardCvv('')
      setCardExpirationDate('')
      setCardName('')
      setCardNumber('')
      setDocument('')
      let newUser = user
      newUser.status = 'paid'
      dispatch({ type: 'CHANGE_USER', payload: newUser })
      localStorage.setItem('user', JSON.stringify(newUser))
      axios.defaults.headers.common['authentication'] = newUser.token
      toast.success('Assinatura efetuada com sucesso!')
    } catch (error) {
      console.log(error)
      toast.error(error.response.data.errors[0].message)
    } finally {
      setIsLoading(false)
      setModalOpen('')
    }
  }

  return (
    <section className='general-configs'>
      <div className='general-configs__container'>
        <div className='general-configs__title'>
          <h1>Configurações</h1>
          {user.manager === true ? (
            <span>
              Mantenha seu perfil atualizado e personalize sua experiência conforme suas
              necessidades. Gerencie suas credenciais, dados de pagamento e usuários com facilidade.
            </span>
          ) : (
            <span>
              Mantenha seu perfil atualizado e personalize sua experiência conforme suas
              necessidades.
            </span>
          )}
        </div>

        <div className='line'></div>

        <div className='general-configs__edit-container'>
          <div className='general-configs__edit'>
            <h1>Editar perfil</h1>
            <span>Verifique se as informações estão corretas antes de salvar.</span>
          </div>

          <form className='general-configs__inputs' onSubmit={verifyNameChanges}>
            <Input placeholder='Nome' label='Nome' value={name} onChange={setName} />
            <Input
              placeholder='Sobrenome'
              label='Sobrenome'
              fullWidth
              value={surname}
              onChange={setSurname}
            />
            <div className='general-configs__inputs-button'>
              <AlertDialogDemo
                title={'Confimar mudanças?'}
                description={
                  'Verifique se os dados estão preenchidos corretamente antes de confirmar.'
                }
                cancelButton={'Cancelar'}
                confirmButton={'Confirmar'}
                modalOpen={modalOpen === 'profile-changes'}
                setModalOpen={setModalOpen}
                confirmAction={submitUserChanges}
                loading={isLoading}
              />
              <Button
                fullWidth
                contained
                color='green'
                type='submit'
                textUppercase
                style={{
                  fontSize: '10px',
                  fontWeight: '700',
                  lineHeight: '10px',
                  padding: '16px 32px',
                }}>
                Salvar mudanças
              </Button>
            </div>
          </form>
        </div>

        <div className='general-configs__authentication-data-container'>
          <div className='general-configs__authentication-data'>
            <div className='authentication-data__title'>
              <h1>Dados de autenticação</h1>
              <span className='authentication-data__suggestion'>
                Altere os dados de autenticação e confirme para validar as alterações.
              </span>
            </div>

            <div className='authentication-data__buttons'>
              <DialogDemo
                openButton={{
                  text: 'Trocar email',
                  icon: 'Mail',
                  sizeIcon: 16,
                  color: 'green',
                  type: 'submit',
                  style: {
                    backgroundColor: '#64FFDA',
                    border: '1px solid transparent',
                    color: '#1B1B1B',
                    height: '32px',
                  },
                }}
                title={'Alterar email'}
                description={'Preencha os campos corretamente para alterar o seu email'}
                confirmButton={'Alterar email'}
                confirmAction={verifyEmailChanges}
                loading={isLoading}
                modalOpen={modalOpen === 'email-change'}
                onOpenChange={e => setModalOpen(e ? 'email-change' : '')}>
                <p className='dialog__label__description'>Preencha com o seu novo email</p>
                <Input
                  placeholder='Email'
                  label='Email'
                  type='email'
                  value={email}
                  onChange={setEmail}
                />
                <p className='dialog__label__description'>Preencha com a sua senha atual</p>
                <Input
                  placeholder='Senha'
                  label='Senha'
                  type='password'
                  value={passwords.current}
                  onChange={value => changePasswords('current', value)}
                />
              </DialogDemo>

              <DialogDemo
                openButton={{
                  text: 'Trocar senha',
                  icon: 'Lock',
                  sizeIcon: 16,
                  color: 'green',
                  type: 'submit',
                  style: { height: '32px', color: '#ffffff' },
                }}
                title={'Alterar senha'}
                description={'Preencha todos os campos corretamente para alterar a sua senha'}
                confirmButton={'Alterar senha'}
                confirmAction={verifyPassword}
                loading={isLoading}
                modalOpen={modalOpen === 'password-change'}
                onOpenChange={e => setModalOpen(e ? 'password-change' : '')}>
                <p className='dialog__label__description'>Preencha com a sua senha atual</p>
                <Input
                  placeholder='Senha'
                  label='Senha'
                  type='password'
                  value={passwords.current}
                  onChange={value => changePasswords('current', value)}
                />
                <p className='dialog__label__description'>Preencha com a sua nova senha</p>
                <Input
                  placeholder='Nova senha'
                  label='Nova senha'
                  type='password'
                  value={passwords.newPassword}
                  onChange={value => changePasswords('newPassword', value)}
                />
                <Input
                  placeholder='Repita a nova senha'
                  label='Repita a nova senha'
                  type='password'
                  value={passwords.confirmNewPassword}
                  onChange={value => changePasswords('confirmNewPassword', value)}
                />
              </DialogDemo>
            </div>
          </div>

          <div className='authentication-data__inputs'>
            <Input disabled placeholder='Email' label='Email' value={user.email} />
          </div>
        </div>

        <div className='general-configs__edit-container'>
          <div className='general-configs__authentication-data'>
            <div className='authentication-data__title'>
              <h1>Minha assinatura</h1>
              <span className='authentication-data__suggestion'>
                {user.status === 'paid' || user.status === 'unpaid'
                  ? 'Altere os dados de pagamento ou cancele sua assinatura a qualquer momento.'
                  : 'Você ainda não possui assinatura. Clique no botão para criar a sua assinatura e aproveitar todos os benefícios.'}
              </span>
            </div>

            <div className='authentication-data__buttons'>
              {user.status === 'paid' || user.status === 'unpaid' ? (
                <>
                  <DialogDemo
                    openButton={{
                      text: 'Pagamento',
                      icon: 'CreditCard',
                      sizeIcon: 16,
                      color: 'green',
                      type: 'submit',
                      style: {
                        backgroundColor: '#64FFDA',
                        border: '1px solid transparent',
                        color: '#1B1B1B',
                        height: '32px',
                      },
                    }}
                    title={'Alterar dados de pagamento'}
                    description={
                      'Preencha os campos atentamente e verifique se os dados estão corretos'
                    }
                    confirmButton={'Alterar dados'}
                    confirmAction={verifySignatureData}
                    loading={isLoading}
                    modalOpen={modalOpen === 'new-asignature'}
                    onOpenChange={e => setModalOpen(e ? 'new-asignature' : '')}>
                    <Input
                      placeholder='0000 0000 0000 0000'
                      label='Número do cartão'
                      value={cardNumber}
                      onChange={value => setCardNumber(creditCard(value))}
                    />
                    <Input
                      placeholder='Nome e sobrenome'
                      label='Nome no cartão'
                      value={cardName}
                      onChange={value => setCardName(value)}
                    />
                    <Input
                      placeholder='000.000.000-00'
                      label='CPF/CNPJ'
                      value={document}
                      onChange={value => setDocument(formatDocument(value))}
                    />
                    <div style={{ display: 'flex', gap: '16px' }}>
                      <Input
                        placeholder='MM/AA'
                        label='Expira em'
                        value={cardExpirationDate}
                        onChange={value => setCardExpirationDate(expiredAt(value))}
                      />
                      <Input
                        placeholder='123'
                        label='CVV'
                        value={cardCvv}
                        onChange={value => setCardCvv(cvvValidator(value))}
                      />
                    </div>
                  </DialogDemo>

                  <DialogDemo
                    openButton={{
                      text: 'Cancelar',
                      icon: 'X',
                      sizeIcon: 16,
                      color: 'green',
                      type: 'submit',
                      style: { height: '32px', color: '#ffffff' },
                    }}
                    title={'Cancelar assinatura'}
                    description={
                      'Ao cancelar sua assinatura, seu acesso à plataforma será limitado ao histórico de atividades já realizadas. Novas interações com a Inteligência Artificial do Spectter não serão permitidas.'
                    }
                    confirmButton={'Cancelar'}
                    confirmAction={cancelSignature}
                    loading={isLoading}
                    modalOpen={modalOpen === 'cancel-signature'}
                    onOpenChange={e => setModalOpen(e ? 'cancel-signature' : '')}></DialogDemo>
                </>
              ) : (
                <Button
                  fullWidth
                  sizeIcon={16}
                  icon={'ShoppingBag'}
                  color={'green'}
                  type={'submit'}
                  textUppercase
                  onClick={() => verifySignatureData()}
                  style={{
                    border: '1px solid #64feda',
                    fontSize: '10px',
                    fontWeight: '700',
                    lineHeight: '10px',
                    borderRadius: '0',
                    height: '32px',
                    color: '#ffffff',
                  }}
                  props>
                  Assinar o Spectter
                </Button>
              )}
            </div>
          </div>

          <div className='general-configs__signature-container'>
            <div className='general-configs__signature-container__price'>
              <span>R$</span>77<span>/mês</span>
            </div>
            <div className='general-configs__signature-container__features'>
              <span>
                GPT-4o <Check width={20} />
              </span>
              <span>
                Transcrição e consulta de áudio/vídeo
                <Check width={20} />
              </span>
              <span>
                Consulta de múltiplos documentos
                <Check width={20} />
              </span>
              <span>
                OCR
                <Check width={20} />
              </span>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default GeneralConfigurations
