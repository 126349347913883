import React, { useState, useEffect } from 'react'
import CustomIcon from '../CustomIcon'

import { useMedia } from '../../hooks/useMedia'

import './index.scss'

const NewFrontBanner = () => {
  const isMobile = useMedia('(min-width: 960px)')
  const [open, setOpen] = useState(true)

  useEffect(() => {
    setOpen(true)
  }, [])

  return (
    <>
      {open && (
        <div className='new-front-banner'>
          <p>
            Descubra o poder do Spectter 2.0 agora mesmo! Teste a nova versão e transforme sua
            experiência com inteligência artificial!
          </p>
          <a title='Acessar novo Spectter' href='https://beta.spectter.com.br'>
            {isMobile ? (
              'Acessar Spectter v2'
            ) : (
              <CustomIcon icon='ArrowUpRight' color='#3b3b3b' size={20} />
            )}
          </a>

          <button className='new-front-banner__close-button' onClick={() => setOpen(false)}>
            <CustomIcon icon='X' color='#ffffff' />
          </button>
        </div>
      )}
    </>
  )
}

export default NewFrontBanner
