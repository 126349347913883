import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

// Components
import CustomTabs from '../../components/DesignSystem/CustomTabs'
import AnimationBg from '../AnimationBg'
import CustomIcon from '../CustomIcon'
import NewFrontBanner from '../NewFrontBanner'

// Styles
import './index.scss'

function getCurrentTab(currentTab, tabs, pathname) {
  if (currentTab?.path === pathname) return currentTab

  const tab = tabs.find(tab => {
    if (tab.isRegex) {
      const pathRegex = new RegExp(tab.path, 'g')

      return pathRegex.test(pathname)
    }

    return pathname.includes(tab.path)
  })

  return tab
}

const HeaderFeatures = () => {
  const tabs = [
    { tabName: 'Consultar Documentos', path: '/lista-consultar-documentos', icon: 'FileSearch' },
    { tabName: 'Criar Documentos', path: '/lista-criar-documentos', icon: 'FilePen' },
    { tabName: 'Transcrição de áudio e vídeo', path: '/transcricoes', icon: 'FileVideo' },
  ]
  const { user } = useSelector(state => state.app)
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [openBanner, setOpenBanner] = useState(true)

  useEffect(() => {
    setOpenBanner(true)
  }, [])

  function handleChangeTab(tab) {
    navigate(tab.path)
  }

  function returnUserName() {
    if (user.surname) return `${user.name} ${user.surname}`

    return user.name
  }

  function scrollToBottom() {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: 'smooth',
    })
  }

  return (
    <>
      {user.status === 'unpaid' && (
        <div
          className='banner__unpaid-alert'
          style={openBanner ? { display: 'flex' } : { display: 'none' }}>
          <div className='banner__unpaid-alert__text'>
            <CustomIcon icon='AlertOctagon' color='#ffffff' size={24} />
            <span>Não foi possível efetuar o pagamento. Por favor, tente novamente.</span>
          </div>
          <div className='banner__unpaid-alert__actions'>
            <button
              onClick={() => {
                navigate('/configuracoes')
                scrollToBottom()
              }}>
              <CustomIcon icon='CreditCard' color='#ffffff' size={16} />
              <span>Ajustar pagamento</span>
            </button>
            <button onClick={() => setOpenBanner(false)}>
              <CustomIcon icon='X' color='#ffffff' size={24} />
            </button>
          </div>
        </div>
      )}

      <NewFrontBanner />

      <div className='header-features'>
        <section className='header-features__banner'>
          <h1>
            Olá, <br /> <strong>{returnUserName()}</strong>
          </h1>
          <p>Simplifique a prática jurídica, uma feature de cada vez.</p>
          <AnimationBg />
        </section>
        <section className='header-features__content'>
          <CustomTabs
            id='header-features-tabs'
            tabs={tabs}
            currentTab={getCurrentTab(tabs[0], tabs, pathname)}
            onChangeTab={handleChangeTab}
          />
        </section>
      </div>
    </>
  )
}

export default HeaderFeatures
